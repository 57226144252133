<template>
	<div class="page xieyi_page">
		<div class="w1000">
			<topHeader :isShowBack="false"></topHeader>
		</div>
		<div class="block30"></div>
		<div class="w1000 xieyi_page_box">
			<div class="conbox" v-html="actObj.contents"></div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import topHeader from "@/components/topHeader/topHeader.vue";
export default {
	components:{
		topHeader
	},
	data() {
		return {
			actObj:{},//协议内容
		};
	},
	mounted: function() {
		this.getpageArticle()
	},
	methods: {
		getpageArticle() {
			var _this = this
			var params = {
				currentPage: 1,
				pageSize: 1,
				typeId:4,
				infoTags:"aboutus"
			}
			this.$http.post('app/article/pageArticle', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						_this.actObj = records[0]
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
		
	}
};
</script>
<style lang="scss" scoped>
	.xieyi_page{
		width: 100%;
		position: relative;
		z-index: 2;
		.xieyi_page_box{
			border-radius: 12px;
			background: #FFF;
			box-shadow: 0px 4px 7px 0px rgba(187, 200, 236, 0.25);
			padding: 32px;
			box-sizing: border-box;
		}
		.conbox{}
	}
</style>
